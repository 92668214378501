<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-envira"></i><strong> Intrants agricoles</strong>
              <info-message>
                Les intrants vous permettent de fournir les nutriments dont la plante a besoin pour sa croissance.
                En plus de ceux proposés, vous pouvez créer vos propres intrants
              </info-message>
              <div class="card-header-actions">
                <b-button variant="success" @click="onExport" title="Exporter en PDF" class="mr-2" v-b-tooltip.hover>
                  <i class="fa fa-upload"></i>
                </b-button>
                
                <b-button variant="primary" @click="newInputModal = true">
                  <i class="fa fa-plus"></i>
                  Nouvel intrant
                </b-button>
                
                <b-modal :title="modalTitle" class="modal-primary" v-model="newInputModal"
                         @hide="onModalClose" size="lg">
                  <b-form-row>
                    <b-col class="mr-4">
                      <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                      
                      <c-input container-class="mb-3" label="Nom de l'intrant" placeholder="Ex: Engrais"
                               v-model="name" :state="nameState">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <c-input container-class="mb-3" type="quantity-unit"
                               :label="selectedInput ?'Quantité totale':'Quantité'" placeholder="Ex: 12"
                               v-model="quantity" :unit.sync="unit" :units="units"
                               :state="quantityState" :disabled="editing" :unit-disabled="editing">
                        Veuillez entrer une quantité
                        <template slot="infoMessage">
                          Choisissez l'unité de la quantité au fond à droite (→).
                        </template>
                      </c-input>
                      
                      <c-input container-class="mb-3" type="price" label="Prix unitaire" placeholder="Ex: 500"
                               v-model="price" :state="priceState" :currency="exploitationCurrency">
                        Veuillez entrer un prix
                      </c-input>
                      
                      <div class="d-inline-flex w-100">
                        
                        <c-input container-class="mb-3" type="select" label="Conditionnnement"
                                 v-model="inputConditioning"
                                 :options="inputConditioningList" :state="inputConditioningState">
                          <template slot="first">
                            <option :value="null">--</option>
                          </template>
                          Veuillez sélectionner un conditionnement ou unité
                        </c-input>
                        <c-input container-class="mb-3 w-25" type="select" label="unité"
                                 v-model="inputConditioningUnit"
                                 :options="inputConditioningUnitList" :state="inputConditioningUnitState">
                          <template slot="first">
                            <option :value="null">--</option>
                          </template>
                          Veuillez sélectionner un conditionnement ou unité
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button"
                                  title="Ajouter un nouveau conditionnement"
                                  @click="createConditioningAlert=true"
                                  v-b-tootltip.hover>
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <b-alert
                        variant="dark"
                        dismissible
                        fade
                        :show="createConditioningAlert"
                        @dismissed="createConditioningAlert=false"
                      >
                        <c-input container-class="mb-3" label="Nom du conditionnement" placeholder="Ex: Gaz..."
                                 v-model="newConditioning" :state="newConditioningState">
                          Veuillez saisir un nom
                        </c-input>
                        
                        <b-input-group v-if="newUnits.length">
                          <b-badge variant="secondary" pill class="m-1 pt-1" v-for="item in newUnits">
                            {{item.name}}
                            <b-link @click.prevent="removeItem(item)">
                              <i class="fa fa-remove text-danger"></i>
                            </b-link>
                          </b-badge>
                        </b-input-group>
                        <p class="text-center" v-else>Nothing for now</p>
                        
                        <b-input-group class="mb-3 mt-1">
                          <b-autocomplete-input placeholder="cherchez une unité" v-model="searchUnit"
                                                :data="searchAutocompleteDataUnits" class="autocomplete"
                                                @hit="submitSearch($event)" ref="searchAutocompleteInput"
                                                :serializer="item => item.name"
                                                :minMatchingChars=0>
                            <template slot="append">
                              <b-button variant="primary" @click.prevent="submitSearch()">
                                Ajouter
                              </b-button>
                            </template>
                          </b-autocomplete-input>
                        </b-input-group>
                        <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitNewConditioning"
                                        :fetching="fetchingNewConditioning">
                          Creer ce conditionement
                        </button-spinner>
                      </b-alert>
                      
                      <c-input container-class="mb-3 mt-3" type="select" label="Entrepôt" v-model="warehouse"
                               :options="warehouses" :state="warehouseState" :disabled="editing">
                        <template slot="first">
                          <option :value="null">-- Sélectionnez un entrepôt --</option>
                        </template>
                        Veuillez sélectionner un entrepôt
                      </c-input>
                    
                    </b-col>
                    <b-col>
                      <c-input type="image" v-model="picture" default="DEFAULT_INPUT_PICTURE"></c-input>
                      <c-input container-class="mb-3" type="textarea" label="Commentaire"
                               placeholder="Entrez un commentaire..." v-model="comment">
                      </c-input>
                    
                    </b-col>
                  </b-form-row>
                  
                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmit"
                                    :fetching="fetchingNewInput">
                      {{submitBtnText}}
                    </button-spinner>
                    <b-button variant="secondary" @click="newInputModal = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
              </div>
            </div>
            <div class="text-center" v-if="fetchingInputList">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <b-row v-else-if="inputs.length" class="exploitations">
              <c-table :table-data="inputs" :fields="fields" :actions="actions" :per-page=5 striped outlined
                       fixed></c-table>
            </b-row>
            <p class="text-center" v-else>Aucun intrant dans cette exploitation.</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Regex, Toast, asset, env, IO, price} from "../../../helpers"
  
  export default {
    name: "ExploitationInputs",
    title: "PIA - Intrants agricoles",
    data() {
      return {
        inputs: [],
        fields: [
          {key: 'picture', label: 'Aperçu', asset: this.asset},
          {key: 'name', label: 'Nom', sortable: true},
          {key: 'qty', label: 'Quantité totale'},
          {key: 'unitPrice', label: 'Prix unitaire'},
          {key: 'comment', label: 'Commentaire'},
          {key: 'actions', label: 'Actions'},
        ],
        actions: [
          {type: 'primary', icon: 'icon-note', title: 'Modifier', handler: this.editInput},
          {type: 'danger', icon: 'icon-trash', title: 'Supprimer', handler: this.deleteInput},
        ],
        newInputModal: false,
        submitted: false,
        error: null,
        fetchingNewInput: false,
        fetchingInputList: false,
        fetchingUnitList: false,
        selectedInput: null,
        picture: null,
        name: '',
        quantity: '',
        price: '',
        unit: null,
        comment: '',
        units: [],
        warehouse: null,
        fetchingWarehouseList: false,
        warehouses: [],
        
        inputConditioningList: [],
        inputConditioning: null,
        inputConditioningUnit: null,
        
        createConditioningAlert: false,
        newConditioning: "",
        newUnits: [],
        searchUnit: null,
        searchAutocompleteDataUnits: [],
        fetchingNewConditioning: false,
        
      }
    },
    created() {
      this.fetchingInputList = true
      Api.get('/exploitation/input/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.inputs = res.data.data.map(item => ({
              ...item,
              qty: this.getQty(item),
              unitPrice: price(item.price)
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingInputList = false
        })
      
      this.fetchingUnitList = true
      Api.get('/unit/list')
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.units = res.data.data.map(item => ({
              value: item.slug,
              text: item.slug
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingUnitList = false
        })
      
      this.fetchingWarehouseList = true
      Api.get('/exploitation/warehouse/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.warehouses = res.data.data.map(item => ({
              value: item.id,
              text: item.name
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingWarehouseList = false
        })
      
      // Retrieve the inputs conditioning list
      Api.get('/exploitation/package/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.inputConditioningList = res.data.data.map(item => ({
              value: {
                id: item.id,
                units: item.units
              },
              text: item.name
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingWarehouseList = false
        })
      
      // Retrieve the units' list exploitation
      Api.get('/exploitation/unit/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.searchAutocompleteDataUnits = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingWarehouseList = false
        })
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      nameState() {
        return !this.submitted || this.name.trim().length >= 3 ? null : false
      },
      quantityState() {
        return !this.submitted || (!this.warehouse && !this.quantity) || Regex.isPositiveNumber(this.quantity)
          ? null : false
      },
      priceState() {
        return !this.submitted || Regex.isPositiveNumber(this.price)
          ? null : false
      },
      warehouseState() {
        return !this.submitted || this.selectedInput || (!this.warehouse && !this.quantity) || this.warehouse != null
          ? null : false
      },
      editing() {
        return !!this.selectedInput
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      },
      submitBtnText() {
        return this.selectedInput ? 'Enregistrer' : 'Créer'
      },
      modalTitle() {
        return this.selectedInput ? 'Modifier l\'intrant' : 'Nouvel intrant'
      },
      exploitationCurrency() {
        return price()
      },
      inputConditioningUnitList() {
        return !this.inputConditioning ? [] : this.inputConditioning.units.map(item => ({
          text: item.name,
          value: item.slug
        }))
      }
    },
    methods: {
      asset(path) {
        return asset(path, env('DEFAULT_INPUT_PICTURE'))
      },
      valid() {
        return this.name.trim().length >= 3
          && ((!this.warehouse && !this.quantity) || Regex.isPositiveNumber(this.quantity))
          && Regex.isPositiveNumber(this.price)
          && (this.selectedInput || (!this.warehouse && !this.quantity) || this.warehouse != null)
      },
      reset() {
        this.submitted = false
        this.error = null
        this.picture = null
        this.quantity = ''
        this.selectedInput = null
        this.comment = ''
      },
      onModalClose() {
        this.reset()
        this.fetchingNewInput = false
        this.name = ''
        this.unit = null
        this.warehouse = null
        this.price = ''
        this.inputConditioning = null
        this.inputConditioningUnit = null
        this.newConditioning = ""
        this.newUnits = []
        this.clearSearchAutocompleteInput()
      },
      getQty(item) {
        return item.quantity ? item.quantity + (item.unit ? ' ' + item.unit : '') : ''
      },
      newInput() {
        this.submitted = true
        if (!this.valid()) return
        
        this.fetchingNewInput = true
        Api.post('/exploitation/input/create', {
          exploitationId: this.exploitationId,
          name: this.name,
          quantity: this.quantity,
          packageId: this.inputConditioning.id,
          unit: this.inputConditioningUnit,
          price: this.price,
          comment: this.comment,
          picture: this.picture,
          warehouseId: this.warehouse,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newInputModal = false
              const input = res.data.data
              this.inputs.push({
                ...input,
                qty: this.getQty(input),
                unitPrice: price(input.price),
              })
              
              Toast.success('Intrant créé avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewInput = false
          })
      },
      updateInput() {
        this.submitted = true
        if (!this.valid()) return
        
        this.fetchingNewInput = true
        Api.post('/exploitation/input/update', {
          exploitationId: this.exploitationId,
          inputId: this.selectedInput.id,
          name: this.name,
          packageId: this.inputConditioning.id,
          unit: this.inputConditioningUnit,
          price: this.price,
          comment: this.comment,
          picture: Regex.isNullOrURL(this.picture) ? null : this.picture,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newInputModal = false
              this.inputs = this.inputs.filter((item, index, array) => item.id !== this.selectedInput.id)
              const input = res.data.data
              this.inputs.push({
                ...input,
                qty: this.getQty(input),
                unitPrice: price(input.price),
              })
              
              Toast.success('Intrant mis à jour avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewInput = false
          })
      },
      onSubmit() {
        this.selectedInput ? this.updateInput() : this.newInput()
      },
      selectInput(input) {
        this.selectedInput = input
        this.picture = asset(input.picture)
        this.name = input.name
        this.unit = input.unit
        this.price = input.price
        this.inputConditioning = this.inputConditioningList.find(item => item.value.id === input.package_id).value
        this.inputConditioningUnit = input.unit
      },
      editInput(input) {
        this.selectInput(input)
        console.log('input')
        console.log(input)
        this.quantity = input.quantity
        this.comment = input.comment
        this.newInputModal = true
      },
      deleteInput(input) {
        const res = confirm(`Etes-vous sûr de vouloir supprimer l'intrant "${input.name}" ?`)
        if (res) {
          Api.post('/exploitation/input/delete', {
            exploitationId: this.exploitationId,
            inputId: input.id
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                this.inputs = this.inputs.filter((item, index, array) => item.id !== input.id)
                Toast.success('Intrant supprimé avec succès !')
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(error => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              // this.fetchingNewEquipment = false
            })
        }
      },
      onExport() {
        IO.exportDataTable(this.inputs, 'inputs', {
          title: 'Exploitation: ' + this.$store.getters.exploitationName,
          name: 'Liste des intrants',
          headers: [
            {title: 'Aperçu', key: 'picture', width: 'auto', asset: this.asset},
            {title: 'Nom', key: 'name', width: 'auto'},
            {title: 'Quantité', key: 'qty', width: 'auto'},
            {title: 'P.U', key: 'unitPrice', width: 'auto'},
            {title: 'Commentaire', key: 'comment', width: '*'},
          ]
        })
      },
      
      // Modal
      clearSearchAutocompleteInput() {
        this.$refs.searchAutocompleteInput.inputValue = ''
        this.search = ''
      },
      removeItem(item) {
        this.newUnits = this.newUnits.filter(el => el.slug !== item.slug)
      },
      submitSearch(item) {
        if (!item && this.searchUnit.trim() !== '')
          item = this.searchAutocompleteData.find(
            el => el.name.toLowerCase().includes(this.searchUnit.toLowerCase())
          )
        
        if (item) {
          if (!this.newUnits.includes(item))
            this.newUnits.push(item)
        }
        else
          alert('Aucun élément trouvé !')
        
        this.clearSearchAutocompleteInput()
      },
      onSubmitNewConditioning() {
        this.fetchingNewConditioning = true
        Api.post('/exploitation/package/create', {
          exploitationId: this.exploitationId,
          name: this.newConditioning,
          units: this.newUnits
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.inputConditioningList = this.inputConditioningList.concat([{
                value: {
                  id: res.data.data.id,
                  units: res.data.data.units
                },
                text: res.data.data.name
              }])
              Toast.success('Nouveau Conditionnement crée avec succès !')
              this.newConditioning = ""
              this.newUnits = []
              this.clearSearchAutocompleteInput()
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewConditioning = false
          })
      }
    }
  }
</script>

<style scoped>
  #input-img{
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
  
  default-unit{
    font-size: 20px;
    font-family: Candara;
    font-weight: bold;
    text-decoration: underline;
  }
  
  text-recommended{
    font-style: italic;
    margin-top: 10px;
    font-weight: 600;
    color: #009000;
  }
  
  text-recommended::after,
  text-recommended::before{
    content: " \2022 ";
  }
  
  .add-button{
    height: 30px;
    width: 30px;
    margin-top: 23px;
    margin-left: 10px;
    margin-right: 2px;
  }
</style>
